import { DropdownMenuItem } from '@/app/components/navbar/DropdownMenuItem';
import externalLinks from '@/utils/externalLinks';
import { Avatar, Group, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useSession } from 'next-auth/react';
import { useCreateSupportTicket } from '../../contexts/CreateSupportTicketContext';
import { AboutRapidModal } from './AboutRapidModal';

export function AdditionalMenu() {
  const { data: session } = useSession();
  const [aboutRapidModalOpened, { open: aboutRapidModalOpen, close: aboutRapidModalClose }] = useDisclosure(false);
  const { open: openTicketModal } = useCreateSupportTicket();

  if (!session?.user) {
    return null;
  }

  return (
    <>
      <Group gap='xs' wrap='nowrap'>
        <Menu shadow='md' width={200}>
          <Menu.Target>
            <Avatar color='gray.6' style={{ cursor: 'pointer' }}>
              ?
            </Avatar>
          </Menu.Target>
          <Menu.Dropdown>
            <DropdownMenuItem target='_blank' link={externalLinks.onlineDocumentation()}>
              Online Documentation
            </DropdownMenuItem>
            <Menu.Item onClick={() => openTicketModal()}>Create a Support Ticket</Menu.Item>
            <DropdownMenuItem target='_blank' link={externalLinks.serviceStatus()}>
              Service Status
            </DropdownMenuItem>
            <Menu.Item onClick={aboutRapidModalOpen}>About RapidPipeline</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <AboutRapidModal opened={aboutRapidModalOpened} close={aboutRapidModalClose} />
    </>
  );
}
