import Link from 'next/link';
import styles from './styles.module.css';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import { useSessionStorage } from '@mantine/hooks';
import { NEWLY_CREATED_WORKFLOWS_IDS } from '../../workflows/constants';

type NavigationItemProps = {
  render: (isActive: boolean) => ReactNode;
  link: string;
};

export function NavigationItem({ link, render }: NavigationItemProps) {
  const pathName = usePathname();
  const [_, setIds] = useSessionStorage({
    key: NEWLY_CREATED_WORKFLOWS_IDS,
    defaultValue: [] as number[],
  });

  return (
    <Link
      className={`self-stretch px-8 ${styles.link}`}
      href={link}
      onClick={() => setIds([])}
    >
      <div className='flex h-full items-center gap-6'>
        {render(link === pathName)}
      </div>
    </Link>
  );
}
