import { useQuery } from '@tanstack/react-query';
import { getImpersonation } from '../utils/getImpersonation';

export const IMPERSONATION_MAIN_QUERY_KEY = 'impersonation';

export const useImpersonationQuery = () => {
  return useQuery({
    queryKey: [IMPERSONATION_MAIN_QUERY_KEY],
    queryFn: () => getImpersonation(),
    throwOnError: false,
  });
};
