import { Text, Anchor, Paper } from '@mantine/core';

export enum BannerType {
  OPEN_BETA = 'OPEN_BETA',
  DISCOUNT = 'DISCOUNT',
}

interface BannerConfig {
  textBeforeLink: string;
  linkUrl: string;
  linkText: string;
  textAfterLink: string;
}

const BANNER_CONFIG: Record<BannerType, BannerConfig> = {
  [BannerType.OPEN_BETA]: {
    textBeforeLink: '🎉 Enjoy our starter plan for free until August 1st! ',
    linkUrl: '/choose_plan',
    linkText: 'Upgrade now',
    textAfterLink: ' to continue enjoying premium features without interruption.',
  },
  [BannerType.DISCOUNT]: {
    textBeforeLink: '💸 Save 25% on ',
    linkUrl: '/choose_plan',
    linkText: 'new plans',
    textAfterLink: ' with code RAPIDLAUNCH25. Offer ends August 1st!',
  },
};

interface BannerProps {
  type: BannerType;
}

const Banner: React.FC<BannerProps> = ({ type }) => {
  const { textBeforeLink, linkUrl, linkText, textAfterLink } = BANNER_CONFIG[type];

  return (
    <Paper
      style={{
        padding: '10px 0',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Text size='md' fw={500}>
        {textBeforeLink}
        <Anchor href={linkUrl} c='teal.9'>
          {linkText}
        </Anchor>
        {textAfterLink}
      </Text>
    </Paper>
  );
};

export default Banner;
