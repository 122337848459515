import { Menu } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { endImpersonation } from '../../../utils/endImpersonation';
import { MAIN_ASSET_QUERY_KEY } from '../../assets/hooks/useAssetsQuery';
import { WORKFLOWS_QUERY_MAIN_KEY } from '../../workflows/components/table/query/useWorkflowsQuery';
import { useGrowthbookContext } from '../../contexts/GrowthbookContext';
import { IMPERSONATION_MAIN_QUERY_KEY, useImpersonationQuery } from '../../../shared/useImpersonationQuery';
import { MAIN_PUBLISHED_QUERY_KEY } from '../../published/hooks/usePublishedQuery';
import { useSession } from 'next-auth/react';
import { canImpersonate } from '../../../utils/canImpersonate';

export const ImpersonationMenuItem = ({ open }: { open: () => void }) => {
  const queryClient = useQueryClient();
  const growthbook = useGrowthbookContext();
  const { data } = useSession();
  const isImpersonationAvailable = canImpersonate(data?.user);

  const { data: impersonation } = useImpersonationQuery();

  const { mutateAsync: endImpersonationAction } = useMutation({
    mutationFn: () => endImpersonation(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          [MAIN_ASSET_QUERY_KEY, WORKFLOWS_QUERY_MAIN_KEY, MAIN_PUBLISHED_QUERY_KEY, IMPERSONATION_MAIN_QUERY_KEY].some(
            (key) => query.queryKey.includes(key)
          ),
      });
    },
  });

  const isImpersonateOn = growthbook?.isOn('impersonate-user');

  if (!isImpersonateOn || !isImpersonationAvailable) {
    return null;
  }

  return impersonation?.isImpersonating ? (
    <Menu.Item onClick={() => endImpersonationAction()}>End Impersonation</Menu.Item>
  ) : (
    <Menu.Item onClick={open}>Impersonate User</Menu.Item>
  );
};
