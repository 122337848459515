import { useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { isInternalRoute } from './internalPaths';

export const useRestrictedPath = () => {
  const { data: session } = useSession();
  const pathname = usePathname();

  const isRestrictedPath = useMemo(
    () => session?.user && isInternalRoute(pathname),
    [pathname, session?.user]
  );

  return { isRestrictedPath };
};
