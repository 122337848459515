import { Group, Menu } from '@mantine/core';
import { IconCrown } from '@tabler/icons-react';
import { ReactNode } from 'react';

interface CrownMenuItemProps {
  children: ReactNode;
  showCrown?: boolean;
  onClick?: () => void;
  link?: string;
}

export function CrownMenuItem({ children, showCrown, onClick, link }: CrownMenuItemProps) {
  return (
    <Menu.Item component={link ? 'a' : 'button'} href={link} onClick={onClick}>
      <Group style={{ width: '100%', justifyContent: 'space-between' }}>
        {children}
        {showCrown && <IconCrown size={16} color='black' />}
      </Group>
    </Menu.Item>
  );
}
