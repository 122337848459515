import { z } from 'zod';

export enum WorkflowTab {
  ALL = 'all',
  CUSTOM = 'custom',
  APPLICATION = 'application',
  GENERAL = 'general',
  DEVICE = 'device',
}

export const TAB_QUERY_KEY = 'activeTab';
export const SEARCH_QUERY_KEY = 'search';

export const WorkflowTabSchema = z.nativeEnum(WorkflowTab);

export const getActiveWorkflowTab = (
  query: string | null | string[] | undefined
): WorkflowTab => {
  if (!query) {
    return WorkflowTab.ALL;
  }

  try {
    return WorkflowTabSchema.parse(query);
  } catch (error) {
    return WorkflowTab.ALL;
  }
};
