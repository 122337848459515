'use client';

import Banner, { BannerType } from '@/app/components/Banner';
import { useSession } from 'next-auth/react';
import { Box, Container, Stack } from '@mantine/core';
import { useRestrictedPath } from '@/shared/useRestrictedPath';
import { useGrowthbookContext } from '@/app/contexts/GrowthbookContext';
import { usePathname } from 'next/navigation';

function wildcardMatch(pattern: string) {
  return pattern.replace(/\*/g, '.*');
}

export default function BannerWrapper({
  excludePaths,
  disableWrapper,
}: {
  excludePaths?: string[];
  disableWrapper?: boolean;
}) {
  const growthbook = useGrowthbookContext();
  const { isRestrictedPath } = useRestrictedPath();
  const { data: session } = useSession();
  const pathname = usePathname();

  const transformedExcludePaths = excludePaths ? excludePaths.map(wildcardMatch) : [];
  const shouldBeExcluded = transformedExcludePaths.some((pattern) => new RegExp(pattern).test(pathname));

  if (!session?.user || !isRestrictedPath || shouldBeExcluded) {
    return null;
  }

  const growthbookLoading = growthbook === null;
  if (growthbookLoading) {
    return null;
  }
  const bannerEnabled = growthbook?.isOn('show-discount-25');

  const user = session.user;
  const hasOldPlan = user && user.plan.details.id < 60;

  if (bannerEnabled && hasOldPlan) {
    if (disableWrapper) {
      return <Banner type={BannerType.DISCOUNT} />;
    } else {
      return (
        <Box mx='sm' mt={16}>
          <Container maw='1440' px={0}>
            <Banner type={BannerType.DISCOUNT} />
          </Container>
        </Box>
      );
    }
  }
  return null;
}
