'use client';

import { Crisp } from 'crisp-sdk-web';
import { useEffect } from 'react';
import { useSession } from 'next-auth/react';

export default function CrispChat() {
  const { data: session } = useSession();
  useEffect(() => {
    Crisp.configure('922e6bf3-2bf5-48d4-ba89-5bd58ef411e1'); // Todo: make this configurable
  }, []);

  useEffect(() => {
    if (session?.user) {
      Crisp.user.setEmail(session.user.email);
      Crisp.session.setData({
        user_id: session.user.id,
      });
    }
  }, [session?.user]);
  return null;
}
