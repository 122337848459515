'use client';

import { NavigationItem } from '@/app/components/navbar/NavigationItem';
import { urlHelper } from '@/utils';
import AssetsIcon from '@/icons/assets.svg';
import { Text } from '@mantine/core';
import PipelineIcon from '@/icons/pipeline.svg';
// import WorkflowIcon from '@/icons/workflow.svg';
import PublishedIcon from '@/icons/published.svg';
import { IconAdjustments } from '@tabler/icons-react';

export function NavbarItems() {
  return (
    <>
      <NavigationItem
        link={urlHelper.assets()}
        render={(isActive) => (
          <>
            <AssetsIcon
              className='h-[26px] w-[26px] rounded pl-[.4rem] pt-[.4rem]'
              style={{
                color: isActive ? 'var(--mantine-color-blue-6)' : null,
                backgroundColor: isActive ? 'var(--mantine-color-blue-0)' : null,
              }}
            />
            <Text fw={getFontWeight(isActive)} size='sm' c='dark.9'>
              Assets
            </Text>
          </>
        )}
      ></NavigationItem>
      <NavigationItem
        link={urlHelper.workflows()}
        render={(isActive) => (
          <>
            <IconAdjustments
              className='plr-[.2rem] h-[26px] w-[26px] py-[.2rem] '
              style={{
                borderRadius: '5px',
                color: isActive ? 'var(--mantine-color-grape-6)' : '',
                background: isActive ? 'var(--mantine-color-grape-1)' : '',
              }}
            />
            <Text fw={getFontWeight(isActive)} size='sm' c='dark.9'>
              3D Processor Presets
            </Text>
          </>
        )}
      ></NavigationItem>
      <NavigationItem
        link={urlHelper.published()}
        render={(isActive) => (
          <>
            <PublishedIcon
              className='plr-[.2rem] h-[26px] w-[26px] py-[.2rem] '
              style={{
                borderRadius: '5px',
                color: isActive ? 'var(--mantine-color-cyan-6)' : null,
                backgroundColor: isActive ? 'var(--mantine-color-cyan-1)' : null,
              }}
            />
            <Text fw={getFontWeight(isActive)} size='sm' c='dark.9'>
              Published Embeds
            </Text>
          </>
        )}
      ></NavigationItem>
    </>
  );
}

function getFontWeight(isActive: boolean) {
  return isActive ? 600 : undefined;
}
